<script setup>
import AshLogo from './icons/AshLogo.vue'
import ClientList from './ClientList.vue'
import { userStore } from '../stores/user'
import { partnersStore } from '../stores/partners'
import classnames from 'classnames'
import logger from '../utils/logger'
import { checkUserToken, clearUserAuth } from '../utils/handleSession'
import { showSuccessToast } from '../utils/toastMessages'
import { setInactivityLogoutTime, getPartnerList } from '../utils/handleSession'
</script>

<script>
export default {
  name: 'NavbarTop',
  components: {
    AshLogo,
    ClientList
  },
  props: {
    showSidebar: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      isAuthenticated: false,
      optionSelected: null,
      clientOptions: [],
      isMobile: false
    }
  },
  async created() {
    const store = userStore()
    const partnerStore = partnersStore()
    const { isAshSuperAdmin } = store.user
    if (isAshSuperAdmin) {
      store.$subscribe(async (mutation, state) => {
        if (!state.user.accessToken) {
          this.isAuthenticated = false
        } else {
          this.optionSelected = this.clientOptions.find(
            (option) => option.clientId === state.user.selectedClientId
          )
          this.isAuthenticated = true
        }
      })
    }

    this.setPartnerOptions()

    partnerStore.$subscribe(async (mutation, state) => {
      this.setPartnerOptions()
    })
  },
  mounted() {
    this.checkDevice()
    window.addEventListener('resize', this.checkDevice)
    this.addEventListeners()
  },
  methods: {
    async getUserPartnerList() {
      try {
        const { user } = userStore()
        const { accessToken } = user
        const parnertList = await getPartnerList(accessToken)
        if (parnertList.length > 0) {
          const { setPartnerList } = partnersStore()
          setPartnerList(parnertList)
        }
      } catch (error) {
        console.error(error)
      }
    },
    setPartnerOptions() {
      const store = userStore()
      const partnerStore = partnersStore()

      const { partners } = partnerStore.partners

      if (partners.length) {
        this.clientOptions = partners.map((partner) => {
          return {
            clientId: partner.partner_id,
            name: partner.partner_name
          }
        })
      }

      if (store.user.accessToken) {
        this.isAuthenticated = true
        this.optionSelected = this.clientOptions.find(
          (option) => option.clientId === store.user.selectedClientId
        )
      }
    },
    checkDevice() {
      this.isMobile = window.matchMedia('(max-width: 640px)').matches
    },
    selectOption(option) {
      this.optionSelected = option
      const { user, setUser } = userStore()

      const { isAshSuperAdmin, selectedClientId } = user

      // if user is not a super admin and the selected client is different from the current client
      // user should be redirected to the login page and login to the target client
      if (!isAshSuperAdmin && selectedClientId !== option.clientId) {
        const swithAccount = {
          action: 'switchAccount',
          clientId: option.clientId
        }
        localStorage.setItem('switchAccount', JSON.stringify(swithAccount))
        this.$router.push('/')
      } else {
        // if the selected client is the same as the current client, do nothing
        if (selectedClientId === option.clientId) {
          return
        }

        setUser({
          ...user,
          minExpDate: setInactivityLogoutTime(),
          selectedClientId: option.clientId
        })
        showSuccessToast('Account Switch Success', 'Success:')
      }
    },
    goToPage(page) {
      this.$router.push(page)
    },
    getTopBarClassNames() {
      return classnames('tw-bg-dark-blue tw-flex tw-gap-4 tw-h-20', {
        'tw-h-20': !this.isMobile,
        'tw-h-32 tw-py-2 !tw-flex-col': this.isMobile && this.isAuthenticated
      })
    },
    getLogoClassNames() {
      return classnames('tw-flex tw-justify-center tw-items-center', {
        'tw-ml-4': !this.isAuthenticated
      })
    },
    handleShowSidebar() {
      this.$emit('showSidebar')
    },
    async resetCookie() {
      // Check if user token is still valid
      const hasUserToken = checkUserToken()
      if (!hasUserToken) {
        //remove event listeners
        this.removeEventListeners()

        // Clear user data
        const { clearUser } = userStore()
        clearUser()
        await clearUserAuth()

        // Redirect to login
        this.$router.push('/')
      }
    },
    gotToHome() {
      localStorage.removeItem('encodedToken')
      this.$router.push('/dashboard')
    },
    async logout() {
      const { clearUser } = userStore()

      try {
        clearUser()
        this.removeEventListeners()
        const isLoggedOut = await clearUserAuth()

        if (isLoggedOut) {
          this.$router.push('/')
        }
      } catch (error) {
        logger.error('An error occurred while performing this request!', error)
      }
    },
    addEventListeners() {
      this.removeEventListeners()
      window.addEventListener('mousemove', this.resetCookie)
      window.addEventListener('keydown', this.resetCookie)
      window.addEventListener('scroll', this.resetCookie)
    },
    removeEventListeners() {
      window.removeEventListener('mousemove', this.resetCookie)
      window.removeEventListener('keydown', this.resetCookie)
      window.removeEventListener('scroll', this.resetCookie)
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkDevice)
  },
  watch: {
    $route() {
      const { user } = userStore()
      const { partners } = partnersStore()

      if (user.accessToken) {
        this.isAuthenticated = true
        this.addEventListeners()
        if (partners.partners.length === 0) {
          this.getUserPartnerList()
        }
      } else {
        this.isAuthenticated = false
        this.removeEventListeners()
      }
    }
  }
}
</script>

<template>
  <div id="navbar" :class="getTopBarClassNames()">
    <div class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-gap-6 tw-w-full">
      <div v-if="isAuthenticated" class="tw-w-[200px] tw-cursor-pointer" @click="handleShowSidebar">
        <div
          class="tw-inline-flex tw-w-full tw-font-light tw-font-noto tw-relative tw-items-center tw-justify-between tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-text-gray-900 tw-shadow-sm"
        >
          <img id="menuIcon" class="navbar-toggle" src="../assets/menu-item.svg" />
        </div>
      </div>
      <div :class="getLogoClassNames()">
        <AshLogo id="ashLogo" @click="gotToHome" class="tw-cursor-pointer" />
      </div>
      <ClientList
        v-if="!isMobile && isAuthenticated"
        :clientOptions="clientOptions"
        @optionSelected="selectOption"
        :selected="optionSelected"
        :is-mobile="isMobile"
      />
      <div class="tw-flex tw-justify-end tw-items-center tw-mr-4 tw-w-full tw-gap-4">
        <!-- <RouterLink to="/help" class="tw-text-white tw-text-[14px]">Help</RouterLink> -->
        <div
          @click="logout"
          v-if="isAuthenticated"
          class="tw-text-white tw-text-[14px] tw-font-aeonik hover:tw-text-white hover:tw-underline hover:tw-underline-offset-4 tw-cursor-pointer"
        >
          Log Out
        </div>
      </div>
    </div>

    <div v-if="isMobile && isAuthenticated" class="tw-w-full tw-px-3">
      <ClientList
        :clientOptions="clientOptions"
        @optionSelected="selectOption"
        :selected="optionSelected"
        :isMobile="isMobile"
      />
    </div>
  </div>
</template>
